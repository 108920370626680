import React, { useEffect, useRefm ,useState,useRef } from 'react';
function Homepage() {
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);
  useEffect(() => {
    const video = videoRef3.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);
  useEffect(() => {
    const video = videoRef1.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);
  useEffect(() => {
    const video = videoRef2.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

<>

    {loading ? (
        <div id="loadings" className="cs_custom_lodar">
          <div className="inner_div btn_animate">
          <div class="containerddd">
            <div class="dot dot-1"></div>
            <div class="dot dot-2"></div>
            <div class="dot dot-3"></div>
          </div>
          </div>
        </div>
    ) : (<></>)}

    <section className="main_home_page_css">
      <div className='main_slider_top'>
        <video
          ref={videoRef}
          preload="auto"
          playsInline
          loop
          controls={false}
          className='backgroundvideo'
        >
          <source src="https://angelpoop.xyz/assetsvideo/newhometop.webm" type="video/webm" />
        </video>
      </div>
      <div className='section_overlay_about' id='about'>
        <div className='container'>
          <h2>The Legend of AngelPoop</h2>
          <p>TON’s Holy Sh*tstorm From on high, the gods watched memes fall from grace. They summoned their most joyful angel, sent down in the form of AngelPoop to bring the lost meme spirit back to Earth. And so, TON was chosen as the sacred ground for this meme revolution. Together, we’re building the ultimate holy sh*t cult, making TON the meme capital of the universe. Join us and let’s take $APOOP to the stars!</p>
        </div>
      </div>
      <div className='about_block' >
      <video
          ref={videoRef1}
          preload="auto"
          playsInline
          loop
          controls={false}
          className='aboutvideo'
          poster="https://angelpoop.xyz/assetsvideo/aboutvideo.webm"
        >
          <source src="https://angelpoop.xyz/assetsvideo/aboutvideo.webm" type="video/webm" />
          <source src="https://angelpoop.xyz/assetsvideo/aboutvideo.mp4" type="video/mp4" />
        </video>
      </div>
      <div className='section_overlay_csscroll'>
        <marquee><img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop </marquee>
      </div>
      <div className='tokefeatures' id='Pooponomics' style={{ backgroundImage: `url('https://angelpoop.xyz/assetsvideo/topbanner.webp')` }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='heading_tag'>
                <h2>Pooponomics</h2>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <ul>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/1.webp" />
                  <span>No tax</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/2-2.webp" />
                  <span>Contract renounced</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/3-3.webp" />
                  <span>Liquidity locked</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/superman.webp" />
                  <span>Supply 1T</span>
                </li>
              </ul>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='tokefeaturesvideoblock'>
                <img src="https://angelpoop.xyz/assetsvideo/angeltoken.webp" style={{width:"100%"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='inner_overlay_animation' id='howtobuy'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='inner_data_wallet'>
                <span>********************************** <i class="fa fa-copy"></i></span>
              </div>
            </div>
          </div>
          <h3>How to Buy</h3>
          <div className='row mainrowcenter'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/LAPTOP.gif" />
                <div className='inner_content_data'>
                  <h4>Wallet Setup</h4>
                  <p>Download MyTonWallet or wallet of your choice, and create a wallet</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/money.gif" />
                <h4>Load Up $TON</h4>
                <p>Add some $TON to the wallet so you can swap it for $APOOP</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/tracter.gif" />
                <h4>Buy $APOOP</h4>
                <p>Swap your $TON for some $APOOP, and confirm the transaction</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/space.gif" />
                <h4>Seize the Opportunity</h4>
                <p>Embrace the crypto community and seize your opportunity for wealth</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='walletid'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='inner_overlay_animation gameplay' id='team'>
          <h3>Gameplay</h3>
          <video
          ref={videoRef3}
          preload="auto"
          playsInline
          loop
          controls={false}
          className='backgroundvideo'
        >
          <source src="https://angelpoop.xyz/assetsvideo/gameplay.webm" type="video/webm" />
        </video>
      </div>
      <div className='inner_overlay_animation'>
        <div className='container'>
          <h3>Roadmap</h3>
          <div className='row roadmapmain'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div class="roadmap-item">
                <span class="roadmap-title">Q2/Q3 2024</span>
                <div class="roadmap-content">
                  <span class="dot"></span>
                  <p><b>✔</b> <small>Idea & Inception</small></p>
                  <p><b>✔</b> <small>Team Building </small></p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div class="roadmap-item">
                <span class="roadmap-title">Q4 2024</span>
                <div class="roadmap-content">
                  <span class="dot"></span>
                  <p><b>✔</b> <small>Memecoin IP registration</small></p>
                  <p><b>✔</b> <small>Flush to earn mini-game</small></p>
                  <p><b>✔</b> <small>Partnerships and $TON event hostings </small></p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div class="roadmap-item">
                <span class="roadmap-title">Q1 2025</span>
                <div class="roadmap-content">
                  <span class="dot"></span>
                  <p><b>✔</b> <small>AI Agent launchpad on $TON</small></p>
                  <p><b>✔</b> <small>Token Generation Event</small></p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div class="roadmap-item">
                <span class="roadmap-title">Q2 2025</span>
                <div class="roadmap-content">
                  <span class="dot"></span>
                  <p><b>✔</b> <small>Multi-chain integration</small></p>
                  <p><b>✔</b> <small>Defi products like $TON bridge </small></p>
                  <p><b>✔</b> <small>Adoption based products like bridge, polymarket etc.</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='inner_overlay_animation withteam' id='team'>
          <div className='container'>
          <h3>Our Team</h3>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/UncleSam1.jpg" />
                <h4>Uncle Sam</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Gyro2.jpg" />
                <h4>Gyro</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Plumpy3.jpg" />
                <h4>Plumpy</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Junior4.png" />
                <h4>Junior</h4>
              </div>
            </div>
          </div>
          {/* <h3 className='teamTitle'>Our Partners </h3>
          <div className='row mainrowcenter'>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/partner1.png" />
                <h4>Uncle Sam</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/partner2.png" />
                <h4>Gyro</h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className='section_overlay_csscroll section_overlay_csscroll2'>
        <marquee><img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop </marquee>
      </div>
    </section>
    </>
  );
}
export default Homepage;